const Images = () => {

    const bgImage = require('../images/hero.jpeg');
    const bgBlank = require('../images/blank_bg.jpeg');
    const profileImage = require('../images/profile.jpg');
    const landingImage = require('../images/Cpromoter.png');
    const build_app_website_banner = require('../images/build_app_website_banner.png');
    const gif1 = require('../images/gif1.gif');

    return {gif1, bgImage: bgImage, profileImage: profileImage, bgBlank: bgBlank, landingImage, build_app_website_banner}
}

export default Images
