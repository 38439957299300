import Images from "../../utilities/images";

const AdsBanner1 = () => {
return <div className="d-flex justify-content-center items-center position-relative">
    <a target="_blank" rel="noreferrer" href="https://t.me/xappdeveloper" className="position-fixed bottom-0">
    <img className="mw-100" alt="ads" src={Images().build_app_website_banner} />
    </a>
</div>
}

export default AdsBanner1;