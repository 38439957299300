import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import requestHandler from "../utilities/requestHandler";

const LeftMenu = () =>{

  const [loggedIn, setLoggedIn] = useState(false)
  const [token, setToken] = useState(localStorage.getItem('access_token'));
  const [account_type, setAccountType] = useState(localStorage.getItem('account_type'));

  const checkIfLoggedIn = async () => {
    let x = await requestHandler.get('auth/logged/user', true);
    if(x != null && x.status === 1){
        let y = x.message;
        setLoggedIn(true)
    }
}

useEffect(()=>{
checkIfLoggedIn()
},[]);

    return (

      <div class="col-auto d-none d-lg-flex">
          <ul class="sw-25 side-menu mb-0 primary" id="menuSide">
            <li>
              <Link to="/">
                <i data-cs-icon="home" class="icon" data-cs-size="18"></i>
                <span class="label">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/recent">
                <i data-cs-icon="news" class="icon" data-cs-size="18"></i>
                <span class="label">Recent</span>
              </Link>
            </li>
            <li>
              <Link to="/user/contents/shared">
                <i data-cs-icon="share" class="icon" data-cs-size="18"></i>
                <span class="label">Shared</span>
              </Link>
            </li>
            <li>
              <Link to="/user/referrals">
                <i data-cs-icon="user" class="icon" data-cs-size="18"></i>
                <span class="label">Referrals</span>
              </Link>
            </li>

{account_type === 'Admin' || account_type === 'Super Admin' ? <>
            <li>
              <a href="#" data-bs-target="#admin">
                <i data-cs-icon="grid-1" class="icon" data-cs-size="18"></i>
                <span class="label">Admin</span>
              </a>
              <ul>
                <li>
                  <Link to="/admin/users">
                    <i data-cs-icon="user" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Users</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/users/top">
                    <i data-cs-icon="user" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Top Users</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/list">
                    <i data-cs-icon="user" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Admin</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/app/info">
                    <i data-cs-icon="square" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">App Info</span>
                  </Link>
                </li>
              </ul>
            </li>
            </> :
            ''}

{account_type === 'Admin' || account_type === 'Super Admin' ? <>
            <li>
              <a href="#" data-bs-target="#content">
                <i data-cs-icon="grid-1" class="icon" data-cs-size="18"></i>
                <span class="label">Content</span>
              </a>
              <ul>
              <li>
                  <Link to="/content/all">
                    <i data-cs-icon="notebook-1" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Contents</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/new">
                    <i data-cs-icon="plus" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Create New</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/filter">
                    <i data-cs-icon="share" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Users Share Reports</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/filter/all">
                    <i data-cs-icon="calendar" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Contents Report</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/pending">
                    <i data-cs-icon="calendar" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Pending</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/shared">
                    <i data-cs-icon="share" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Shared</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/not-shared">
                    <i data-cs-icon="info-hexagon" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Not Shared</span>
                  </Link>
                </li>
              </ul>
            </li>
            </>
            :
            '' }

            {account_type === 'Vendor' || account_type === 'Admin' || account_type === 'Super Admin' ?<li>
              <a href="#" data-bs-target="#vendor">
                <i data-cs-icon="grid-1" class="icon" data-cs-size="18"></i>
                <span class="label">Coupons</span>
              </a>
              <ul>
              <li>
                  <Link to="/vendor/coupons">
                    <i data-cs-icon="notebook-1" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">All Coupons</span>
                  </Link>
                </li>
                <li>
                  <Link to="/vendor/coupons/used">
                    <i data-cs-icon="chart-3" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Used Coupons</span>
                  </Link>
                </li>
                <li>
                  <Link to="/vendor/coupons/unused">
                    <i data-cs-icon="chart-2" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Unused Coupons</span>
                  </Link>
                </li>
                <li>
                  <Link to="/vendor/coupons/search">
                    <i data-cs-icon="search" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Search Coupon</span>
                  </Link>
                </li>
                <li>
                  <a href="/auth/coupon/verify">
                    <i data-cs-icon="check-circle" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Verify Coupon</span>
                  </a>
                </li>
              </ul>
            </li> 
            :
            ''}

            <li>
              <a href="#" data-bs-target="#profile">
                <i data-cs-icon="user" class="icon" data-cs-size="18"></i>
                <span class="label">Account</span>
              </a>
              <ul>
              <li>
                  <Link to="/user/profile">
                    <i data-cs-icon="user" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Profile</span>
                  </Link>
                </li>
                <li>
                  <Link to="/user/banks">
                    <i data-cs-icon="suitcase" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Bank/Mobile</span>
                  </Link>
                </li>
                <li>
                  <Link to="/user/transactions">
                    <i data-cs-icon="list" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Transactions</span>
                  </Link>
                </li>
               {/* <li>
                  <Link to="/user/profile/overview">
                    <i data-cs-icon="file-chart" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Overview</span>
                  </Link>
              </li> */}
                <li>
                  <Link to="/user/profile/password/update">
                    <i data-cs-icon="shield" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Update Password</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          
        </div>
    )
}
export default LeftMenu;