
import { useEffect, useState } from "react";
import Loading from "../../utilities/Loading";
import HomeContents from "./homeContents";
import PopularContent from "./popularContent";
import requestHandler from "../../utilities/requestHandler";
import UserOverview from "../user/userOverview";
import ContentsOverview from "../content/contentsOverview";

const Home = () => {

    const [announcement, setAnnouncement] = useState([])
    const [popUp, setPopUp] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    const [accountType, setAccountType] = useState(localStorage.getItem('account_type'))
    const [lastPopId, setLastPopId] = useState(localStorage.getItem('last_pop_id'))

    const checkIfLoggedIn = async () => {
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            //window.location.href = "/auth/login"
            window.location.href = "/home"
        }
        else{
            setLoggedIn(true);
        }
      }

    const getAnnouncement = async () => {
        try{
            let x = localStorage.getItem('last_pop_id');
            if(x !== null) setLastPopId(x)
        }
        catch(e){
            console.log(e)
        }
        let x = await requestHandler.get('admin/announcement', false);
        if(x != null && x.status === 1){
            setAnnouncement(x.message)
            //console.log(x.message)
            if(announcement.id !== undefined){
                
                if(lastPopId === null && parseInt(announcement.id) > 0){
                    setPopUp(true)
                    setLastPopId(x.message.id.toString())
                }
                else if(parseInt(lastPopId) !== parseInt(announcement.id)){
                    setPopUp(true)
                }
                else {
                    localStorage.setItem('last_pop_id', x.message.id.toString())
                    setLastPopId(x.message.id.toString())
                    setPopUp(true)
                }
            }
        }
    }

    const closePopUp = () => {
        setPopUp(false)
    }

    useEffect (()=>{
        checkIfLoggedIn()
        getAnnouncement()        
    },[announcement])

    const PopUp = () => {
        return(
            <div class="modal fade modal-under-nav modal-search modal-close-out show" id="popUpModal" tabindex="-1" role="dialog" aria-modal="true" style={{display: 'block'}}>
                 <div class="fixed-background"></div>
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button type="button" class="btn-close btn btn-icon btn-icon-only btn-foreground" data-bs-dismiss="modal" aria-label="Close" onClick={closePopUp}></button>
          </div>
          <div class="modal-body ps-5 pe-5 pb-0 border-0">
            <h4>{announcement.subject}</h4>
          </div>
          <div class="modal-footer border-top justify-content-start ps-5 pe-5 pb-3 pt-3 border-0">
                    <div class="justify-content-start">
                        <div style={{whiteSpace: "pre-wrap"}} class="text-normal">{announcement.announcement} </div>
              </div>
            </div>

          </div>
        </div>
      </div>
        )
    }

    return (
        <div class="col">
           
            {popUp? <PopUp/> : ''}
            
            {!loggedIn? <Loading/>
            :
            <div className="row">
            <div class="col-md-6 col-xl-8 col-xxl-9 mb-5">                
                
                {/*<HomeContents />*/}
                {accountType === "Admin" || accountType === "Super Admin"? <ContentsOverview/> : <UserOverview />}
                
            </div>
            <div class="col-md-1 col-xl-4 col-xxl-9 mb-5">
            <PopularContent/>
            </div>
            </div>
            }
            
            </div>
    )
}

export default Home;